<template>
  <div class="addproduct--container">
    <add-product-content
      @on:product-submitted="onProductCreated"
      @on:cancel-product-create="onCancelProductCreate"
    />
  </div>
</template>
<script>

import AddProductContent from '@/components/app/data/add/AddEditProductContent.vue'

export default {
  name: 'AddEditProduct',
  components: { AddProductContent },
  methods: {
    onProductCreated() {
      this.$router.push('/data')
    },
    onCancelProductCreate() {
      this.$router.push('/data')
    },
  },
}
</script>
